import { ModulePublishingState } from '../../../@types/module-publishing-state';
import api, { ResponsePayloadWithData } from '../../../api';
import { MedialinkSorting } from '../browse/medialinkSortingOption';

export type Module = {
  id: string;
  name: string;
  niceName: string;
  catalogName: string;
  slug: string;
  methodSlug: string;
  subjectName: string;
  subjectSlug: string;
  years: string[];
  weight: number;
  incomplete: boolean;
  hasExercises: boolean;
  hasExerciseEntrance: boolean;
  defaultSorting?: MedialinkSorting;
  disableLessonSorting: boolean;
  hostedExternally: boolean;
  subModules: string[];
  licenses: {
    hasDemoProductLicenseForTeacher: boolean;
    hasDigitalManualLicenseForTeacher: boolean;
    hasDocumentLicenseForTeacher: boolean;
    hasDocumentLicenseForStudent: boolean;
    isActiveBook: boolean;
  };
  bookType?: string;
  releaseDate?: string;
  styling?: {
    logo?: string;
    visual?: string;
    cover?: string;
    primaryColor?: string;
  };
  externalContentUrl?: string;
  enableSlideSetsAccess: boolean;
  publishingState: ModulePublishingState;
};

export const getModuleBySlug = async (moduleSlug: string) => {
  return api.get<Module>(`/studio/modules/slug/${moduleSlug}`);
};

export const getSubModulesForModule = async (moduleId: string) => {
  const { data } = await api.get<ResponsePayloadWithData<Module[]>>(`/studio/modules/${moduleId}/sub-modules`);

  return data;
};

export async function getSuperModuleById(superModuleId: string) {
  const [module, { data: subModules }] = await Promise.all([
    getModuleById(superModuleId),
    getSubModulesForModule(superModuleId),
  ]);

  return {
    ...module,
    subModules,
  };
}

export async function getModuleById(moduleId: string) {
  const { data } = await api.get<Module>(`/studio/modules/${moduleId}`);

  return data;
}

export type SuperModule = Awaited<ReturnType<typeof getSuperModuleById>>;

export type ModuleContainingUserMaterial = {
  id: string;
  catalogName: string;
  niceName: string;
  publishingState: ModulePublishingState;
  weight: number;
  subjectSlug: string;
  books: {
    id: string;
    niceName: string;
    weight: number;
  }[];
};

export async function getModulesContainingUserMaterial(group: StudioGroup = 'SO') {
  const { data } = await api.get<ResponsePayloadWithData<Array<ModuleContainingUserMaterial>>>(
    '/studio/user/modules-containing-user-material',
    {
      params: {
        group,
      },
    },
  );
  return data;
}
