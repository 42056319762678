import { RequiredFields } from '../../../@types/utils';
import api, { ResponsePayloadWithData } from '../../../api';

export type PlatformType = 'Android' | 'iOs' | 'Web intern' | 'Web extern' | 'P intern';
type LinkedAppType = 'WordList' | 'digibook' | 'MediaLink';
type TargetAudience = 'student' | 'teacher';
type AppPublishingState = 'draft' | 'review' | 'preview' | 'published';

export type App = {
  id: string;
  name: string;
  appType: {
    id: string;
    name: string;
    isExercise: boolean;
    isDigibook: boolean;
    icon: {
      color: string;
      initials: string;
    };
  };
  moduleId: string;
  module: {
    niceName: string;
    bookType: string;
    cover: string;
    styling: {
      primaryColor: string;
    };
  };
  licenseProduct: string;
  platformType: PlatformType;
  platformUrl?: string;
  platformLinkType?: LinkedAppType;
  linkedApp?: {
    id: string;
    type: LinkedAppType;
    manualIncluded?: boolean;
    miniSiteUrl?: string;
  };
  moduleSpecific: boolean;
  ownedByPelckmans: boolean;
  allowedToOpenInNewTab: boolean;
  target: TargetAudience;
  releaseDate?: string;
  published: boolean;
  state: AppPublishingState;
  cover?: string;
};

export const getAppsForModule = async (moduleId: string, includeSubModules = true) => {
  const { data } = await api.get<ResponsePayloadWithData<App[]>>(
    `studio/modules/${moduleId}/apps?includeSubModules=${includeSubModules}`,
  );

  return data;
};

export type AppType = {
  id: string;
  name: string;
  description?: string;
  screenshots?: string[];
};

export const getAppTypeById = async (appTypeId: string) => {
  return api.get<AppType>(`studio/app-types/${appTypeId}`);
};

export function isAppWithPlatformUrl(app: App): app is RequiredFields<App, 'platformUrl'> {
  return app.platformUrl !== undefined;
}

export function isAppWithLinkedApp(app: App): app is RequiredFields<App, 'linkedApp'> {
  return 'linkedApp' in app;
}
